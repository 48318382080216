import { useState } from "react";
import LogoGov from "../../assets/LogoGovBA.png";
import InputEmail from "../Input/email";
import { validateEmail } from "../../services/authApi";
import { createProfile } from "../../services/connector";
import { useParams } from "react-router-dom";
import { Loader } from "../Loader/loader";
import { isValidEmail } from "../../utils/regex";
import * as S from "./styled"


export function FormAutentication() {

    const [user_email, setUserEmail] = useState("");
    const [user_localization] = useState("");
    const [user_sector, setUserSector] = useState("");
    const [user_location_city, setUserLocationCity] = useState("");
    const { numTelefone } = useParams()
    const [userError, setUserError] = useState(true);
    const [userSuccess, setUserSuccess] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!isChecked) {
            setUserError("Por favor, aceite o termo de consentimento!")
            userErrorTimeout('')

        } else {
            setLoading(true);
            try {
                await verifyExecution();
            } catch (error) {
                console.error("Erro geral:", error);
            }
        }
        setLoading(false);
        setIsChecked(false);
    }

    const verifyExecution = async () => {
        try {
            await verifyValidateEmail()
        } catch (error) {
            console.error("Erro ao verificar perfil:", error);
        }
    };

    const verifyValidateEmail = async () => {
        try {
            const validationResult = await validateEmailAuth();
            if (validationResult) {
                await createUser(validationResult);
            } else {
                console.log("Erro na validação de e-mail");
            }
        } catch (error) {
            console.error("Erro:", error);
        }
    };

    const validateEmailAuth = async () => {

        try {
            if (!user_email.trim()) {
                setUserError("Informe o seu E-mail!")
                userErrorTimeout('')
            } else if (!isValidEmail.test(user_email)) {
                console.log(user_email)
                setUserError("Informe o E-mail no formato válido!")
                userErrorTimeout('')

            } else {
                const { data } = await validateEmail(user_email);
                const email = data.user_email
                const login = data.user_name
                return {
                    login,
                    email,
                };

            }
            //setValidateError("Usuário autenticado com sucesso!");
        } catch (error) {

            setUserError("E-mail Inválido!");
            userErrorTimeout('')
            console.log("Erro ao validar o email:", error);
            throw error;
        }
    }

    const createUser = async ({ login, email }) => {
        const user = {
            user_email: email,
            user_name: login,
            user_sector: user_sector,
            user_localization: user_localization,
            user_location_city: user_location_city,
            user_phone: numTelefone,
        };

        console.log("user", user);

        try {
            user.user_location_city = "Salvador"
            user.user_sector = "Sede"
            user.user_localization = "SEC-Sede"

            const response = await createProfile(user);

            if (response.success) {
                setUserSuccess("Usuário autenticado com sucesso!");
                userSuccessTimeout()
            }
            else {
                setUserError(response.message);
                userErrorTimeout()
            }
        } catch (error) {
            console.error("Erro ao criar o usuário:", error);
        }
    };

    const userSuccessTimeout = () => {
        setTimeout(() => {
            setUserSuccess(false);
            setUserEmail('');
            setUserSector('');
            setUserLocationCity('');
        }, 4000);
    }

    const userErrorTimeout = () => {
        setTimeout(() => {
            setUserError(false);
        }, 4000);
    }

    return (
        <>
            {loading ? (
                <Loader />
            ) :
                (null)}
            <S.Container>
                <S.Form>
                    <div className="area-form">
                        <div className="header-form">
                            <span className="logo-gov">
                                <img src={LogoGov} alt="LogoGov" width={"190px"} height={"90px"} />
                            </span>
                            <div className="text-header">
                                <p className="title-header">
                                    FORMULÁRIO DE AUTENTICAÇÃO PARA USO DO CHATBOT DA CENTRAL DE ATENDIMENTO
                                </p>
                            </div>
                        </div>
                        <div className="validate-user">
                            {userError ? (
                                <span className="error">{userError}</span>
                            ) : null}
                            {userSuccess ? (
                                <span className="success">{userSuccess}</span>
                            ) : null}
                        </div>
                        <form id="formRadio" className="bodyForm" onSubmit={handleSubmit}>
                            <div>
                                <InputEmail
                                    type="text"
                                    name="email"
                                    id="email"
                                    onChange={(e) => { setUserEmail(e.target.value) }}
                                    required
                                />
                            </div>
                            <div className="term-consent">
                                <p>
                                    Para fins de segurança, solicitamos que seja aceito o termo de  consentimento de uso. Ao utilizar este canal, declaro estar ciente e de  acordo em disponibilizar meus dados funcionais para abertura e acompanhamento de tickets conduzido pela Central de Serviços.
                                </p>
                                <p>
                                    Este consentimento é necessário para atendimento aos requisitos da Lei Geral de Proteção de Dados (LGPD).
                                </p>
                            </div>
                            <div className="accept-term">
                                <label className="check-term">
                                    <input
                                        type="checkbox"
                                        name="Termo"
                                        id="Termo"
                                        value="Termo "
                                        checked={isChecked}
                                        onChange={() => setIsChecked(!isChecked)}
                                    // required
                                    />
                                </label>
                                <span for="checkboxMarked" class="term-4">Termo de consentimento</span>

                            </div>
                            <div className="btn-form">
                                <input
                                    type="submit"
                                    name="Entrar"
                                    value="Entrar"
                                    className="enter-btn-form"
                                // onClick={handleButtonClick}
                                // clicked={buttonClick}
                                />
                            </div>
                        </form>
                    </div>
                </S.Form>
            </S.Container>
        </>
    );

}

import React from 'react';
import PropTypes from 'prop-types';
import { Email } from './styled';


function InputEmail({ id, name, value, onChange }) {

    return (
        <Email>
            <div className="input-email">
                <span className="title-email">Digite seu e-mail E-nova ou Educação:</span>
                <label>
                    <input
                        type="text"
                        id={id}
                        name={name}
                        value={value}
                        onChange={onChange}
                        placeholder="Digite seu e-mail para acessar"
                        // required
                    />
                </label>
            </div>
        </Email>
    )
};

InputEmail.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default InputEmail;
import styled from "styled-components";

export const Container = styled.div`
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
// background: linear-gradient(to right bottom, #f4fcfc, #ecf4fc, #d9e9f2, #ecf4fc, #bcd4e4, #b4dce8, #8dd3e8); 

@media (max-width:600px) {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0;

}
@media (max-width: 375px) {
    span.logo-gov {
        padding-top: 8rem;
    }   
}
`
export const Form = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;

.area-form {
    display:flex;
    flex-direction: column;
    background: #FFFFFF 0% 0% ;
    width: 28%;
    // height: 95%;
    border-radius: 28px;
    padding-bottom: 1.4rem;
}

div.header-form {
    width: 100%;
    justify-content: center;
    display: block;
}

span.logo-gov {
    display:flex;
    justify-content: center;
    width: 100%;
    padding-top: 25px;
}

div.text-header  {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    // width: 76%;
    color: #696969;
    // margin-left: 3.2rem;
}

p.title-header {
    font: normal normal bold 12px/15px Montserrat;
    display: flex;
    text-align: center;
    justify-content: center; 
    opacity: 1;
    text-transform: uppercase;
    margin-top: 13px;
    
}
 
form.bodyForm{
    padding: 1rem 3rem;
    margin-top: -1rem;
}

span.question-profile {
    display: flex;
    font: normal normal bold 13px/16px Montserrat; 
    color: #1d7acc;
}

div.area-radio{
    display: flex;
    padding-top: 10px;
    width: 100%;
    align-items: center;
    font: normal normal normal 12px/14px Montserrat; 
    color: #1d7acc;    
    
}

div.radio-button{
    margin-right: 1.2rem;

}

input[type="radio" i] {
    margin-right: 0.3rem;
    cursor: pointer;
}

div.campusProfile{
    padding-top: 15px;
}


div.term-consent {
    // margin-top: 2.5rem;
}

p{
    font: normal normal normal 10px/11px Montserrat;
    text-align: left;   
    margin-left: 0rem;
    text-align: justify;
}

div.accept-term {
    display: flex;
    justify-content: center;
    margin-top: 1.1rem;
    
}

input[type="checkbox" i]{
    cursor:pointer;
}


span.term-4 {
    font: normal normal bold 13px/14px Montserrat;
    color: #707070;
    opacity: 1;
    text-transform: uppercase;
    margin-left: 0.3rem;
}

div.btn-form{
    display:flex;
    padding-top: 18px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

input.enter-btn-form {
    width: 300px;
    height: 42px;
    // background: #0093DD 0% 0% no-repeat padding-box;
    border-radius: 7px;
    border: 2px;
    opacity: 1;
    transition: 0.3s;
    color: #FFFFFF;
    font-size: 15px;
    cursor: pointer;
    background-color: ${props => props.clicked ? '#4682B4' : '#0093DD'};   
}

input.enter-btn-form:hover {
    background-color: #0282c2;
}

div.validate-user{
    display: flex;
    width: 100%;
    height: 22%;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.5rem 0.1rem;
    margin-left: 0.6rem;
}

span.error{ 
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FF0000;
    font: normal normal normal 12px/16px Montserrat; 
    padding: 3px 15px;
    border-radius: 4px;
}

span.success{   
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #079902;
    font: normal normal normal 12px/16px Montserrat; 
    padding: 3px 15px;
    border-radius: 4px;
    margin-left: -0.8rem;
}


@media (max-width:1200px) {
    .area-form{
        width: 400px;
    }
    div.area-radio{
        display: block; 
    }
    input.enter-btn-form {
        width: 280px;
        height: 40px;
    }
    
    div.term-consent {
        margin-top: 2.5rem;
   
    }

    
}  

@media (max-width:600px) {
    width: 100%;
    height: 100%;
    border-radius: 0px;

    .area-form{
        width: 400px;
    }
    
    p.title-header {
        font: normal normal bold 12px/12px Montserrat;   
    }

    input.enter-btn-form {
        width: 200px;
        height: 32px;
    }
    div.text-header  {
        display: block;
        justify-content: center;
        align-items: center; 
    }
    p.title-header {
        font: normal normal bold 10px/10px Montserrat;}
    }
    
    
   
}

@media (max-width: 340px) {
    span.logo-gov {
        padding-top: 8rem;
    }
    input.enter-btn-form {
        margin-bottom: 1rem;
    }
    span.error{ 
        font: normal normal normal 12px/12px Montserrat; 
    }
    
    span.success{   
        font: normal normal normal 12px/12px Montserrat; 
        
    }  
}
`

export const Dropdown = styled.div`
display: flex;
justify-content: center;
flex-direction:column;

.area-dropdown {
    display:flex; 
}

.acting {
    font: normal normal bold 12px/14px Montserrat; 
    color: #1d7acc;
    margin-right: 0.7rem;
}

.sl-acting{
    display:flex;
}

.options-sector {
    flex-direction:column;
    margin-top: 10px; 
}

.options-nte {
    margin-top: 10px;
}

@media (max-width:890px) {
    margin-top: 1.2rem;
    .sl-acting{
        display: block;
    }
}

`
import styled from "styled-components";

export const Login = styled.div`
margin-bottom: 1.6rem; 

span.title-login {
    font: normal normal bold 12px/14px Montserrat;
    color: #1d7acc;
}
div.input-login {
    height: 5vh;
    margin-top: -3px;
    margin-bottom: 8px;

    input{

        width: 75%;
        padding: 2px;
        margin: 8px 0;
        box-sizing: border-box;
        border: none;
        border-bottom: 1px solid #9c9c9c;
    
    
        font: normal normal italic 11px/14px Montserrat;
    
        outline: 0;
        box-shadow: 0 0 0 0;
    
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus {
            caret-color: #000000; 
            background-color: transparent !important; 
            -webkit-box-shadow: 0 0 0px 1000px white inset; 
        }
        
        input::placeholder {
            color: #4a4949;
        }
    }
    
}

@media (max-width:870px) {
    margin-bottom: 0rem;
}

@media (max-width:600px) {
    margin-bottom: 1.4rem;
}
`
export const Email = styled.div`
margin-bottom: 1.6rem; 

span.title-email {
    font: normal normal bold 13.5px/16px Montserrat;
    color: #1d7acc;
}
div.input-email {
    height: 5vh;
    text-align: center;


    input{

        width: 88%;
        padding: 2px;
        margin: 8px 0;
        box-sizing: border-box;
        border: none;
        border-bottom: 1px solid #9c9c9c;
    
    
        font: normal normal italic 11px/14px Montserrat;
    
        outline: 0;
        box-shadow: 0 0 0 0;
    
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus {
            caret-color: #000000; 
            background-color: transparent !important; 
            -webkit-box-shadow: 0 0 0px 1000px white inset; 
        }
        
        input::placeholder {
            color: #4a4949;
        }
    }
    
}


@media (max-width:1200px) {
    margin-bottom: 0rem;
}

@media (max-width:600px) {
    margin-bottom: 1.4rem;
}

@media (max-width:400px) {
    
    span.title-email {
    font: normal normal bold 11px/11px Montserrat;

    div.input-email{
        input{
            font: normal normal italic 8px/10px Montserrat;
        }
    }
}
}
`
export const Password = styled.div`
margin-top: 1.5rem;

span.title-password {
    font: normal normal bold 12px/14px Montserrat;
    color: #1d7acc;
}
div.input-password {
    height: 2rem;
    margin-top: 1rem;
    margin-bottom: 1.8rem; 
    
    input{

        width: 75%;
        padding: 2px;
        margin: 8px 0;
        box-sizing: border-box;
        border: none;
        border-bottom: 1px solid #9c9c9c;
    
    
        font: normal normal italic 11px/14px Montserrat;
    
        outline: 0;
        box-shadow: 0 0 0 0;
    
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus {
            caret-color: #000000; 
            background-color: transparent !important; 
            -webkit-box-shadow: 0 0 0px 1000px white inset; 
        }
        
        input::placeholder {
            color: #4a4949;
        }
    }  
}
`